<template>
  <div id="newly1">
    <div class="hearder">
      <ul>
        <li @click="navigation(1)" :class="{ activeClass: isactive == 1 }">
          基础设置
        </li>
        <li @click="navigation(2)" :class="{ activeClass: isactive == 2 }">
          加密设置
        </li>
        <li @click="navigation(3)" :class="{ activeClass: isactive == 3 }">
          观看终端设置
        </li>
        <li @click="navigation(4)" :class="{ activeClass: isactive == 4 }">
          跑马灯设置
        </li>
      </ul>
    </div>
    <div class="main">
      <!-- 基础设置 -->
      <div class="title2">基础设置</div>
      <div class="divider"></div>
      <div class="model">
        <div class="flex">
          <div class="left">
            <span class="red">*</span>
            方案名称
          </div>
          <div class="rigth">
            <el-input
              style="width: 618px"
              type="text"
              placeholder="请输入加密方案名称"
              v-model="post.template_name"
              maxlength="12"
              show-word-limit
            ></el-input>
          </div>
        </div>

        <div class="flex">
          <div class="left" style="margin-left: 5px">方案默认状态</div>
          <div class="rigth">
            <el-radio-group v-model="post.status">
              <el-radio :label="1">启用</el-radio>
              <el-radio :label="2">停用</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
      <!-- 加密设置 -->
      <div class="title">回放加密设置</div>
      <div class="divider"></div>

      <div class="model">
        <div class="flex">
          <div class="left1">
            转码分辨率
            <helpIcon
              style="margin-left: 6px"
              prompt="加密时会对视频进行转码，转码后修改分辨率对已加密的视频无效，对未加密的视频有效"
            ></helpIcon>
          </div>
          <div class="rigth">
            <el-radio-group v-model="post.zm_spec">
              <el-radio :label="1">高清(720p)</el-radio>
              <el-radio :label="2">蓝光(1080p)</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>

      <!-- 观看终端设置 -->
      <div class="title">观看终端设置</div>
      <div class="divider"></div>

      <div class="model">
        <div class="flex1">
          <div class="left1">
            <span class="red">*</span>
            允许观看终端
          </div>
          <div class="rigth" style="width: calc(100% - 136px)">
            <!-- <el-checkbox-group v-model="checkedBox" @change="check1"> -->
            <div class="mb20">
              <el-checkbox
                :true-label="1"
                :false-label="2"
                v-model="checkedBox[0]"
                @change="checkterminal1"
              >
                PC浏览器
              </el-checkbox>
            </div>
            <div
              class="mb20"
              style="height: 1px; background: #e8e8e8; width: 100%"
            ></div>
            <div
              class="mb20"
              :style="checkedBox[1] == 1 ? 'padding-bottom: 150px;' : ''"
            >
              <el-popover
                placement="bottom-start"
                width="630"
                trigger="manual"
                :value="checkedBox[1] == 1"
              >
                <div class="h5Full">
                  <div class="top">
                    <span class="t1">允许系统全屏观看</span>
                    <span class="t2">
                      <el-switch
                        :width="31"
                        active-value="1"
                        inactive-value="2"
                        v-model="post.is_h5_play_fullscreen"
                        @change="h5FullScreenChange"
                      ></el-switch>
                    </span>
                    <span class="t3">
                      <el-popover
                        placement="bottom-end"
                        width="446"
                        trigger="hover"
                      >
                        <div class="h5Full-preview">
                          <div class="title">全屏观看(开启后)</div>
                          <div class="img img1">
                            <img src="~@ass/img/1.1.5/img_qpgkk.png" alt="" />
                          </div>
                          <div class="title">横屏观看(关闭后)</div>
                          <div class="img">
                            <img src="~@ass/img/1.1.5/img_qpgkg.png" alt="" />
                          </div>
                        </div>
                        <span slot="reference">预览效果</span>
                      </el-popover>
                    </span>
                  </div>
                  <div class="bottom">
                    重要提示：开启后，学员使用手机微信观看加密视频可以切换系统全屏观看，体验更好；由于微信播放器的功能限制，跑马灯、图片水印、版权声明、追溯系统会失效（PC端有效），无法百分百保护视频，请谨慎开启！
                    关闭后，学员全屏播放，只是将页面横屏观看，所有保护功能生效！
                  </div>
                </div>
                <el-checkbox
                  slot="reference"
                  :true-label="1"
                  :false-label="2"
                  v-model="checkedBox[1]"
                  @change="checkterminal2"
                >
                  微信h5(公众号)
                </el-checkbox>
              </el-popover>
            </div>
            <div
              class="mb20"
              style="height: 1px; background: #e8e8e8; width: 100%"
            ></div>
            <div class="mb20 flex-center">
              <el-checkbox
                :true-label="1"
                :false-label="2"
                v-model="checkedBox[2]"
                @change="checkterminal3"
                :disabled="jg_is_pc_client == 2"
              >
                PC客户端
                <span v-if="jg_is_pc_client == 2">(请联系客服开通)</span>
              </el-checkbox>
              <el-button
                v-if="jg_is_pc_client == 2"
                type="text"
                style="margin-left: 40px"
                @click="status_openingfunction = true"
                class="shili"
                size="medium"
              >
                联系客服
              </el-button>
              <template v-if="jg_is_pc_client == 1 && checkedBox[2] == 1">
                <div style="margin-left: 69px">绑定观看设备</div>
                <helpIcon
                  style="margin-left: 6px"
                  prompt="开启后，学员使用PC客户端观看时，会提示绑定观看设备；绑定后，所有已加密的课程在客户端观看都只能在绑定的设备上观看，其他设备无法观看；如需解绑，可在学员模块查看；为了保证视频安全，开启绑定后，不建议同时勾选多个观看终端，只允许PC客户端观看即可"
                ></helpIcon>
                <el-switch
                  style="margin-left: 20px"
                  active-value="1"
                  inactive-value="2"
                  v-model="post.is_bind_pc"
                ></el-switch>
                <el-popover
                  style="margin-left: 42px"
                  placement="top"
                  trigger="click"
                  content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。"
                >
                  <img src="../../assets/img/1.3.8.2/img_slt.png" alt="" />
                  <el-button
                    slot="reference"
                    type="text"
                    class="shili"
                    size="medium"
                  >
                    查看示例
                  </el-button>
                </el-popover>
                <el-button
                  @click="downLoad"
                  type="text"
                  class="shili"
                  style="margin-left: 40px"
                  size="medium"
                >
                  下载Windows客户端
                </el-button>
              </template>
            </div>
            <div
              class="mb20"
              style="height: 1px; background: #e8e8e8; width: 100%"
            ></div>
            <div style="position: relative">
              <el-checkbox
                v-if="ishowxiao"
                :true-label="1"
                :false-label="2"
                v-model="checkedBox[3]"
                @change="checkterminal4"
              >
                小程序
              </el-checkbox>
              <div
                v-if="this.checkedBox[3] == 2"
                @click="xiaochengxu"
                style="
                  height: 50px;
                  width: 100px;
                  position: absolute;
                  top: -20px;
                  z-index: 20;
                "
              ></div>
            </div>
            <!-- </el-checkbox-group> -->
          </div>
        </div>
      </div>

      <!-- 跑马灯设置 -->

      <div class="title">
        跑马灯设置
        <el-switch
          style="margin-left: 20px"
          active-value="1"
          inactive-value="2"
          v-model="post.is_float_words"
        ></el-switch>
      </div>
      <div class="divider"></div>
      <div class="horse" v-if="post.is_float_words == 1">
        <div class="horse_left">
          <div class="model">
            <div class="flex1" style="margin-top: 0">
              <div class="left">
                <span class="red">*</span>
                跑马灯内容
              </div>
              <div class="rigth">
                <!-- {{ checkedBox2 }} -->
                <el-checkbox-group class="check2" v-model="checkedBox2">
                  <div class="mb20">
                    <el-checkbox :label="1">用户昵称</el-checkbox>
                  </div>
                  <div class="mb20">
                    <el-checkbox :label="2">用户ID</el-checkbox>
                  </div>
                  <div class="mb20">
                    <el-checkbox :label="3">
                      用户手机号(学员需先绑定手机)
                    </el-checkbox>
                  </div>
                  <div class="mb20">
                    <el-checkbox :label="4" @change="check2">
                      <el-input
                        :disabled="inputstatus"
                        size="small"
                        type="text"
                        style="width: 320px"
                        placeholder="输入自定义文字"
                        v-model="post.content"
                        maxlength="20"
                        show-word-limit
                      ></el-input>
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
            </div>
          </div>

          <div class="model">
            <div class="flex">
              <div class="left" style="margin-right: 85px">字号大小</div>
              <div class="rigth">
                <el-select
                  :disabled="check3status"
                  style="width: 101px; margin-right: 30px"
                  v-model="post.font_size"
                  size="small"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in getNumbers(12, 100)"
                    :key="item"
                    :label="item + 'px'"
                    :value="item"
                  ></el-option>
                </el-select>
                <el-checkbox
                  :true-label="1"
                  :false-label="2"
                  v-model="post.font_zoom"
                  @change="check3"
                >
                  随机缩放
                </el-checkbox>
              </div>
            </div>
          </div>

          <div class="model">
            <div class="flex">
              <div class="left" style="margin-right: 100px">透明度</div>
              <div class="rigth">
                <el-select
                  style="width: 101px; margin-right: 30px"
                  v-model="post.opacity"
                  size="small"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in getNumbers(0, 101)"
                    :key="item"
                    :label="item + '%'"
                    :value="item"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div class="model">
            <div class="flex">
              <div class="left">跑马灯颜色</div>
              <div class="rigth">
                <el-color-picker
                  v-model="post.font_color"
                  size="small"
                ></el-color-picker>
              </div>
            </div>
          </div>

          <div class="model">
            <div class="flex1">
              <div class="left">出现位置</div>
              <div class="rigth">
                <el-radio-group v-model="post.position">
                  <div class="mb20">
                    <el-radio :label="1">从左往右</el-radio>
                    <el-radio :label="2">从右往左</el-radio>
                  </div>
                  <div class="mb20">
                    <el-radio :label="4">从下往上</el-radio>
                    <el-radio :label="3">从上往下</el-radio>
                  </div>
                  <div class="mb20">
                    <el-radio :label="5">随机位置</el-radio>
                  </div>
                </el-radio-group>
              </div>
            </div>
          </div>

          <div class="model">
            <div class="flex1">
              <div class="left">出现频率</div>
              <div class="rigth">
                <el-radio-group v-model="post.rate">
                  <div class="mb20">
                    <el-radio :label="1">一直出现</el-radio>
                    <el-radio :label="2">高频</el-radio>
                  </div>
                  <div class="mb20">
                    <el-radio :label="3">中频</el-radio>
                    <el-radio :label="4">低频</el-radio>
                  </div>
                </el-radio-group>
              </div>
            </div>
          </div>

          <div class="model">
            <div class="flex1">
              <div class="left">出现速度</div>
              <div class="rigth">
                <el-radio-group v-model="post.speed">
                  <div class="mb20">
                    <el-radio :label="1">快速</el-radio>
                    <el-radio :label="2">中速</el-radio>
                  </div>
                  <div class="mb20">
                    <el-radio :label="3">低速</el-radio>
                  </div>
                </el-radio-group>
              </div>
            </div>
          </div>
        </div>
        <div class="horse_right">
          <div
            style="
              width: 300px;
              background: #fff;
              width: 380px;
              padding: 20px;
              box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            "
          >
            <div class="right_font1">效果预览</div>
            <div class="rignt_tu">
              <div style="width: 100%; height: 100%" id="watermarkContain">
                <watermarkPreView :options="post"></watermarkPreView>
              </div>
            </div>
          </div>
        </div>
      </div>

      <el-button
        type="primary"
        @click="submit"
        style="width: 130px; margin: 40px 5px 0"
      >
        保存
      </el-button>
      <!-- {{post}} -->
      <!-- 申请开通的弹窗 -->
      <openingfunction
        v-if="status_openingfunction"
        :dialogstatus.sync="status_openingfunction"
      ></openingfunction>
      <loading v-show="isLoading" leftPos="0"></loading>
    </div>
  </div>
</template>

<script>
import openingfunction from '@/components/copyrightprotect/openingfunction'
import helpIcon from '@/components/helpIcon'
import watermarkPreView from './watermarkPreView'
export default {
  name: 'newly1',
  data() {
    return {
      ishowxiao: true,
      isLoading: false,
      jg_is_pc_client: 2,
      //控制联系客服开通弹窗
      status_openingfunction: false,
      dateList: [],
      inputstatus: true,
      check3status: false,
      checkedBox2: [1, 2],
      check2_input: '',
      checkedBox: [1, 1, 1, 2], //允许观看终端
      post: {
        is_float_words: '1',
        template_name: '', //方案名称
        status: 1, //方案状态
        zm_spec: 1, //转码分辨率
        is_pc_play: 1,
        is_h5_play: 1,
        is_bind_pc: '2', //绑定观看设备
        is_client_play: 1, //客户端
        is_xcx_play: 2, //小程序
        content_type: '', //好了
        content: '',
        font_size: 18,
        opacity: 50,
        font_zoom: 2,
        font_color: '#FFFFFF',
        position: 1,
        rate: 1,
        speed: 1,
        is_h5_play_fullscreen: '2',
      },
      checked: '',
      options: [],
      isactive: 1,
      radio3: 1,
      color: 'rgba(19, 206, 102, 0.8)',
      radio: 1, //方案状态
      radio2: 1, //分辨率
      lastval: '', //选择的最后的值
      value: '',
    }
  },
  watch: {
    checkedBox2: {
      handler(val) {
        this.post.content_type = val.join(',')
        //如果返回的val值中有4,改变input的disable值
        if (val.some((s) => s == 4)) {
          this.inputstatus = false
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.jg_is_pc_client = this.$store.state.userInfo.jg_is_pc_client
    if (this.jg_is_pc_client == 2) {
      this.checkedBox = [1, 1]
    }
    if (this.$route.query.status == 2) {
      this.getDate()
    }
    this.listenerFunction()
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.listenerFunction)
  },
  components: {
    helpIcon,
    watermarkPreView,
    openingfunction,
  },
  mounted() {
    this.$root.$children[0].childPageOptions[
      this.$root.$children[0].childPageOptions.length - 1
    ].name = this.$route.query.name

    // 允许h5全屏模块兼容显示 否则直接显示样式有问题
    if (this.checkedBox[1] == 1) {
      this.checkedBox.splice(1, 0, 2, 2)
      this.$nextTick(() => {
        this.checkedBox.splice(1, 0, 1, 2)
      })
    }
  },
  methods: {
    // h5全屏操作
    h5FullScreenChange(val) {
      if (val == 1) {
        this.$confirm(
          '开启后，移动端无法保证视频防盗保护，请确保已经阅读红字重要提示！是否确定开启？',
          '允许系统全屏观看',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        )
          .then(() => {
            this.post.is_h5_play_fullscreen = '1'
          })
          .catch(() => {
            this.post.is_h5_play_fullscreen = '2'
          })
      } else {
        this.post.is_h5_play_fullscreen = '2'
      }
    },
    // 下载客户端
    downLoad() {
      this.$store.dispatch(
        'downLoad',
        this.$store.state.userInfo.pc_client_address
      )
    },
    getDate() {
      this.isLoading = true
      this.$http({
        url: `/VideoSafeTemplate/editVideoSafe?template_id=${this.$route.query.template_id}`,
        callback: ({ data, code }) => {
          if (code == 200) {
            this.dateList = data
            // this.checkedBox2 = data.content_type.split(',')
            this.checkedBox = [
              Number(data.is_pc_play),
              Number(data.is_h5_play),
              Number(data.is_client_play),
              Number(data.is_xcx_play),
            ]
            const newcheckedBox2 = []
            data.content_type
              .split(',')
              .forEach((i) => newcheckedBox2.push(Number(i)))
            this.checkedBox2 = newcheckedBox2
            // console.log(this.checkedBox2)
            this.post = _.assign({}, this.post, {
              is_float_words: data.is_float_words,
              template_name: data.template_name, //方案名称
              status: Number(data.status), //方案状态
              zm_spec: Number(data.zm_spec), //转码分辨率
              content: data.content,
              is_pc_play: Number(data.is_pc_play),
              is_h5_play: Number(data.is_h5_play),
              is_client_play: Number(data.is_client_play),
              is_xcx_play: Number(data.is_xcx_play),
              font_size: Number(data.font_size),
              opacity: Number(data.opacity),
              font_zoom: Number(data.font_zoom),
              font_color: data.font_color,
              position: Number(data.position),
              rate: Number(data.rate),
              speed: Number(data.speed),
              is_bind_pc: String(data.is_bind_pc),
              is_h5_play_fullscreen: String(data.is_h5_play_fullscreen || 2),
            })
            if (data.font_zoom == 1) {
              this.check3status = true
            }
            this.isLoading = false
          }
        },
        error: (error) => {
          if (error.code == 204) {
            this.dateList = []
          }
        },
      })
    },
    // change_check2(val) {
    //   const val2 = val.split('')
    //   if(val2.length<1){
    //     this.checkedBox2 = this.checkedBox2.filter(f=>f != 4)
    //   }
    // },
    vertify() {},
    //保存
    submit() {
      const template_name = this.post.template_name
      if (
        template_name == 'undefined' ||
        template_name == null ||
        template_name == ''
      ) {
        this.$root.prompt({
          msg: '请填写或勾选内容，*号表示为必填！ ',
        })
        return
      }
      const checkedBox_other = this.checkedBox.filter((f) => f == 1).length
      if (checkedBox_other < 1) {
        this.$root.prompt({
          msg: '请填写或勾选内容，*号表示为必填！ ',
        })
        return
      }
      if (this.checkedBox2.length < 1 && this.post.is_float_words == 1) {
        this.$root.prompt({
          msg: '请填写或勾选内容，*号表示为必填！ ',
        })
        return
      }

      // if (
      //   this.checkedBox2.length == 1 &&
      //   this.checkedBox2[0] == 4 &&
      //   this.post.content == '' &&
      //   this.post.is_float_words == 1
      // ) {
      //   this.$root.prompt({
      //     msg: '请填写或勾选内容，*号表示为必填！ '
      //   })
      //   return
      // }
      const aa = this.checkedBox2.some((f) => f == 4)
      if (aa) {
        if (this.post.content == '') {
          this.$root.prompt({
            msg: '请填写或勾选内容，*号表示为必填！ ',
          })
          return
        }
      }
      if (this.$route.query.status == 1) {
        this.$nextTick(() => {
          this.$http({
            url: '/VideoSafeTemplate/addVideoSafe',
            data: this.post,
            callback: ({ code }) => {
              if (code == 200) {
                this.$root.prompt({
                  msg: '保存成功',
                  type: 'success',
                })
                this.$router.push({
                  path: '/videoSafe/video',
                })
                // this.dateList = data
              }
            },
            error: (error) => {
              if (error.code == 204) {
                // this.dateList = []
              }
            },
          })
        })
      }
      if (this.$route.query.status == 2) {
        this.$nextTick(() => {
          // console.log(this.post)
          const post2 = _.merge({}, this.post, {
            template_id: this.$route.query.template_id,
          })
          this.$http({
            url: '/VideoSafeTemplate/editVideoSafe',
            data: post2,
            callback: ({ code }) => {
              if (code == 200) {
                this.$root.prompt({
                  msg: '保存成功',
                  type: 'success',
                })
                this.$router.push({
                  path: '/videoSafe/video',
                })
              }
            },
            error: (error) => {
              if (error.code == 204) {
                // this.dateList = []
              }
            },
          })
        })
      }
    },
    getNumbers: function (start, stop) {
      return new Array(stop - start).fill(start).map((n, i) => n + i)
    },
    listenerFunction() {
      document.addEventListener('scroll', this.handleScroll, true)
    },
    handleScroll() {
      // console.log(window.pageYOffset)
      if (window.pageYOffset <= 194) {
        this.isactive = 1
      } else if (window.pageYOffset <= 310) {
        this.isactive = 2
      } else if (window.pageYOffset <= 360) {
        this.isactive = 3
      } else if (window.pageYOffset > 375) {
        this.isactive = 4
      }
    },
    check1() {
      const selcet = this.checkedBox.filter((f) => f == 1)
      if (selcet < 1) {
        this.$root.prompt({
          msg: '该模块必须有一项勾选！',
        })
      }
    },

    checkterminal1(val) {
      this.post.is_pc_play = val
      this.check1()
    },
    checkterminal2(val) {
      this.post.is_h5_play = val
      this.check1()
    },
    checkterminal3(val) {
      if (val == 2) {
        this.post.is_bind_pc = 2
      }
      this.post.is_client_play = val
      this.check1()
    },
    xiaochengxu() {
      this.$confirm(
        '开启后，已加密的视频可在小程序端观看，但由于微信播放器的功能限制，跑马灯、图片水印、版权声明、追溯系统会失效，无法百分百保护视频，请谨慎开启！',
        '开启小程序观看',
        {
          confirmButtonText: '开启',
          cancelButtonText: '取消',
        }
      ).then(() => {
        this.ishowxiao = false
        this.checkedBox[3] = 1
        this.post.is_xcx_play = 1
        this.ishowxiao = true
        this.check1()
      })
    },
    checkterminal4(val) {
      this.post.is_xcx_play = val
      this.check1()
    },
    check2(val) {
      if (val) {
        this.inputstatus = false
      } else {
        this.inputstatus = true
      }
    },
    check3(val) {
      if (val == 1) {
        this.check3status = true
      } else if (val == 2) {
        this.check3status = false
      }
    },
    navigation(val) {
      this.isactive = val
      switch (val) {
        case 1:
          window.scrollTo(0, 0)
          break
        case 2:
          window.scrollTo(0, 200)
          break
        case 3:
          window.scrollTo(0, 320)
          break
        case 4:
          window.scrollTo(0, 400)
          break
      }
    },
  },
}
</script>
<style lang="scss" scoped>
#newly1 {
  background-color: #fff;
  padding: 20px;
  ::v-deep .shili {
    &.el-button--text {
      padding: 0px;
    }
  }
  .activeClass {
    color: #0aa29b !important;
  }
  .hearder {
    right: 20px;
    top: 30%;
    z-index: 999;
    // transform: translateX(90px);
    position: fixed;
    display: flex;
    width: calc(100% - 267px);
    height: 126px;
    width: 93px;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 5px;

    ul {
      padding-left: 11px;
      li {
        font-size: 12px;
        color: #333333;
        line-height: 30px;
        cursor: pointer;
      }
    }
  }

  .main {
    .title,
    .title2 {
      margin-top: 40px;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 21px;
    }
    .title2 {
      margin-top: 0;
    }
    .divider {
      width: 100%;
      height: 1px;
      background: #e8e8e8;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .horse {
      display: flex;
      .horse_left {
        width: 540px;
      }
      .horse_right {
        z-index: 1;
        position: fixed;
        top: 56%;
        left: 55%;
        padding-right: 20px;
        width: calc(100% - 540px);
        .right_font1 {
          font-size: 18px;
          font-weight: bold;
          color: #333333;
          line-height: 18px;
          margin-bottom: 21px;
        }
        .rignt_tu {
          width: 380px;
          height: 214px;
          position: relative;
          overflow: hidden;
          background-color: #000;
        }
      }
    }
    .model {
      font-size: 14px;
      color: #333333;
      line-height: 19px;
      margin-left: 20px;
      .flex {
        margin-top: 20px;
        display: flex;
        align-items: center;
        .left {
          margin-right: 74px;
        }
        .left1 {
          margin-right: 42px;
        }
        .check2 {
          ::v-deep .checkbox-group {
            width: 200px;
          }
        }
      }
      .flex1 {
        margin-top: 20px;
        display: flex;
        .left {
          margin-right: 74px;
        }
        .left1 {
          margin-right: 42px;
        }
      }
    }
  }
}
#watermarkContain {
  background: url('~@ass/img/1.1.5/img_xgyl.png') no-repeat center 100%/100%;
}
.h5Full {
  padding: 14px;
  .top {
    display: flex;
    align-items: center;
    .t1 {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      line-height: 14px;
    }
    .t2 {
      margin: 0 20px;
    }
    .t3 {
      flex: 1;
      font-size: 14px;
      color: #1b9d97;
      line-height: 14px;
      text-align: right;
      cursor: pointer;
    }
  }
  .bottom {
    font-size: 12px;
    color: #ff3535;
    line-height: 16px;
    margin-top: 13px;
  }
}
.h5Full-preview {
  padding: 8px;
  .title {
    font-size: 14px;
    font-weight: 600;
    color: #4a4a4a;
    line-height: 14px;
    margin-bottom: 8px;
  }
  .img {
    width: 100%;
    height: 100%;
    display: block;
    &.img1 {
      margin-bottom: 30px;
    }
  }
}
</style>
