 <template>
  <div></div>
</template>

<script>
let _node
const getWatermarkContainNode = function () {
  if (!_node) {
    _node = document.getElementById('watermarkContain')
  }
  return _node
}

const scale = 2.5

class Watermark {
  constructor(option, style = {}) {
    this.option = _.assign(
      {},
      {
        // 1 从左到右 2 从右到左  3 从上到下 4 从下到上 5 随机
        moveType: 1,
        // 1 从上方开始  2 从下方开始 (适用 moveType: 1 2 )
        // 3 从左侧开始  4 从右侧开始 (适用 moveType: 3 4 )
        positionType: 3,
        // 是否间隔显示
        timeDifference: 0,
        // 视觉差
        spacing: 0,
        // 动画时长
        animationDuration: 10000,
        // 最大字体大小
        maxFontSize: 99,
        minFontSize: 12,
        // 字体动画 增大 缩小
        fontSizeAnimation: false,
      },
      option
    )

    // 字体是往大加 还是 往小减
    // 1 往大  2 往小
    this._fontDirection = 1

    // 动画开始时间
    this.startTime = Date.now()
    this.startTime2 = Date.now()

    // 随机滚动的方向值 0 正向 1 反向
    this._direction = {
      x: 0,
      y: 0,
    }

    // 默认样式
    this.style = _.assign(
      {},
      {
        position: 'absolute',
        opacity: '0.5',
        transform: 'translateX(0)',
        display: 'block',
        margin: '0',
        padding: '10px',
        border: '0',
        color: '#fff',
        visibility: 'visible',
        'font-size': '20px',
        'user-select': 'none',
        'text-indent': '0',
        'line-height': 'initial',
        'z-index': '9999999999',
        'font-family':
          'SimHei, "Microsoft JhengHei", Arial, Helvetica, sans-serif',
      },
      style
    )

    // 备份的样式
    this._newStyle = _.assign({}, this.style)

    // video容器
    this._videoBox = getWatermarkContainNode()
    // 添加的水印节点是否存在
    this._node = null

    this.init()
  }

  // 初始化水印
  init() {
    // 添加水印
    this.animationCallback()
  }

  // 获取容器大小
  getVideoContainerSize() {
    return {
      width: this._videoBox.offsetWidth,
      height: this._videoBox.offsetHeight,
    }
  }

  // 获取数字
  getParseFloat(val) {
    try {
      const value = val.split('(')[1].split(',')
      return {
        x: parseFloat(value[0]) || 0,
        y: parseFloat(value[1]) || 0,
      }
    } catch (e) {
      return {
        x: 0,
        y: 0,
      }
    }
  }

  animationDuration(val, name = 'startTime', animationDuration) {
    animationDuration = animationDuration || this.option.animationDuration
    const delta = Math.min(
      this.option.animationDuration,
      Date.now() - this[name]
    )
    return (delta / animationDuration) * (val || 0)
  }

  // 获取位置信息
  getPosition() {
    // video区域
    const videoBox = this.getVideoContainerSize()

    // 水印宽高
    const watermarkWidth = this._node.offsetWidth
    const watermarkHeight = this._node.offsetHeight

    const moveType = Number(this.option.moveType)

    switch (moveType) {
      case 1:
        this._newStyle.left = `-${watermarkWidth}px`
        this._newStyle.transform = `translate3d(${this.animationDuration(
          videoBox.width + watermarkWidth
        )}px, 0, 0)`
        break
      case 2:
        this._newStyle.right = `-${watermarkWidth}px`
        this._newStyle.transform = `translate3d(-${this.animationDuration(
          videoBox.width + watermarkWidth
        )}px, 0, 0)`
        break
      case 3:
        this._newStyle.top = `-${watermarkHeight}px`
        this._newStyle.transform = `translate3d(0, ${this.animationDuration(
          videoBox.height + watermarkHeight
        )}px, 0)`
        break
      case 4:
        this._newStyle.bottom = `-${watermarkHeight}px`
        this._newStyle.transform = `translate3d(0, -${this.animationDuration(
          videoBox.height + watermarkHeight
        )}px, 0)`
        break
      case 5:
        // X轴偏移量
        var xMaxBoundary = Math.floor(videoBox.width - watermarkWidth)
        var x = this.animationDuration(xMaxBoundary)
        // Y轴偏移量
        var yMaxBoundary = Math.floor(videoBox.height - watermarkHeight)
        var y = this.animationDuration(
          yMaxBoundary,
          'startTime2',
          this.option.animationDuration - 1500 / scale
        )

        if (this._direction.x == 1) {
          x = xMaxBoundary - x
        }

        if (this._direction.y == 1) {
          y = yMaxBoundary - y
        }

        if (this._direction.y == 1 && yMaxBoundary == y) {
          this.startTime2 = Date.now()
        }

        this._newStyle.transform = `translate3d(${x}px, ${y}px, 0)`

        if (x >= xMaxBoundary || x <= 0) {
          this.startTime = Date.now()
          this._direction.x = x <= 0 ? 0 : 1
        }
        if (y >= yMaxBoundary || y <= 0) {
          this.startTime2 = Date.now()
          this._direction.y = y <= 0 ? 0 : 1
        }
        break
    }

    const positionType = Number(this.option.positionType)

    if ([1, 2].includes(moveType)) {
      switch (positionType) {
        case 2:
          this._newStyle.bottom = '0'
          break
        default:
          this._newStyle.top = '0'
          break
      }
      this._newStyle['white-space'] = 'nowrap'
    }

    if ([3, 4].includes(moveType)) {
      switch (positionType) {
        case 3:
          this._newStyle.left = '0'
          break
        default:
          this._newStyle.right = '0'
          break
      }
      this._newStyle['word-break'] = 'break-all'
    }
  }

  // 设置字体动态大小
  setFontSizeAnimation() {
    let fontSize = parseInt(this._newStyle['font-size'])

    if (this._fontDirection == 1) {
      this._newStyle['font-size'] = `${fontSize + 1}px`

      // 如果到达最大值
      if (fontSize >= this.option.maxFontSize) {
        this._fontDirection = 2
      }
    } else {
      this._newStyle['font-size'] = `${fontSize - 1}px`

      // 如果到达最小值
      if (fontSize <= this.option.minFontSize) {
        this._fontDirection = 1
      }
    }
  }

  // 添加水印
  add() {
    // 开始的时候 只默认添加节点
    if (!this._node) {
      this._videoBox.insertAdjacentHTML(
        'beforeend',
        `<div style="position: absolute;opacity:0;padding:10px;font-size:${
          this.style['font-size']
        };line-height: initial;
          ${
            this.option.moveType == 3 || this.option.moveType == 4
              ? 'word-break:break-all;'
              : 'white-space:nowrap;'
          }font-family:SimHei, 'Microsoft JhengHei', Arial, Helvetica, sans-serif">
          <div>${this.option.horse_content}</div>
        </div>`
      )
      // 保存节点
      this._node = this._videoBox.lastElementChild
    } else {
      // 设置初始化位置
      this.getPosition()

      // 字体放大 缩小动画
      if (this.option.fontSizeAnimation) {
        this.setFontSizeAnimation()
      }

      let style = ''

      for (var key in this._newStyle) {
        const item = this._newStyle[key]
        style += `${key}: ${item} !important;`
      }

      this._node.setAttribute('style', style)
    }
  }

  // 执行动画
  animationCallback() {
    const transform = this.getParseFloat(this._newStyle.transform || 0)

    let nodeWidth
    let nodeHeight
    try {
      nodeWidth = this._node.offsetWidth
      nodeHeight = this._node.offsetHeight
    } catch (e) {
      nodeWidth = 0
      nodeHeight = 0
    }

    const timer = () => {
      // 重置节点
      this.reset()
      // 延迟某些时间 开启下一轮播放
      clearTimeout(this.animationTimer)
      this.animationTimer = setTimeout(() => {
        // 重置节点
        this.reset()
        this.animationCallback()
        this.animationTimer = null
      }, this.option.timeDifference || 0)
    }

    // 随机位置需要 先进行定时器
    if (
      !this.animationTimer &&
      this.option.timeDifference > 0 &&
      this.option.moveType == 5
    ) {
      clearTimeout(this.moveType5Times)
      this.moveType5Times = setTimeout(() => {
        timer()
      }, 15000)
    }

    if (
      this.option.moveType == 1 || this.option.moveType == 2
        ? Math.abs(transform.x) < this.getVideoContainerSize().width + nodeWidth
        : this.option.moveType == 3 || this.option.moveType == 4
        ? Math.abs(transform.y) <
          this.getVideoContainerSize().height + nodeHeight
        : true
    ) {
      this.animationFrame = window.requestAnimationFrame(() => {
        this.add()

        // 执行动画
        this.animationCallback()
      })
    } else {
      timer()
    }
  }

  // 删除水印
  reset() {
    // 删除节点
    if (this._node) {
      this._node.parentNode.removeChild(this._node)
      // 重置当前节点
      this._node = null
    }

    // 频率定时器
    clearTimeout(this.animationTimer)
    clearTimeout(this.moveType5Times)
    this.animationTimer = null
    this.moveType5Times = null

    // 重置动画开始时间
    this.startTime = Date.now()
    this.startTime2 = Date.now()

    // 重置样式
    this._newStyle = _.assign({}, this.style)

    // 取消动画队列
    cancelAnimationFrame(this.animationFrame)
    this.animationFrame = null
  }
}

// 品牌保护
class BrandProtection {
  constructor(option) {
    this.option = _.assign(
      {},
      {
        // 水印文件
        logo: {
          url: '',
          opacity: 0.5,
          size: 5,
          // 右上角 右下角 左上角 左下角 居中
          position: 3,
        },
        // 版权声明
        text: {
          // 上方 中间 下方
          position: 1,
          // 高速 中速 低速
          speed: 2,
          content: '',
        },
      },
      option
    )

    this._node = null

    // 动画开始时间
    this.startTime = Date.now()

    // 品牌文字 节点
    this.brandTextNode = null

    this.init()
  }

  init() {
    this.remove()
    this.add()

    this.animationStart()
  }

  animationStart() {
    try {
      const brandText = this._node.firstElementChild || {
        firstElementChild: [{ scrollWidth: 0, clientWidth: 0 }],
      }
      // 文本节点
      const brandTextNode = (this.brandTextNode = brandText.firstElementChild)
      const scrollWidth = brandTextNode.scrollWidth
      // 文本节点宽度
      const width = brandTextNode.clientWidth

      // 如果品牌文字超过 显示动画
      const watermarkContain = getWatermarkContainNode()
      if (
        this.option.text.content &&
        (scrollWidth > width || width > watermarkContain.offsetWidth)
      ) {
        this.brandTextNode.style.marginLeft = `${watermarkContain.offsetWidth}px`
        this.animationCallback()
      } else {
        // 否则每隔5s重置一次试图
        clearTimeout(this.animationStartTimer)
        this.animationStartTimer = setInterval(() => {
          this.init()
        }, 5000)
      }
    } catch (e) {
      // 否则每隔5s重置一次试图
      clearTimeout(this.animationStartTimer)
      this.animationStartTimer = setInterval(() => {
        this.init()
      }, 5000)
    }
  }

  // 获取数字
  getParseFloat(val) {
    try {
      const value = val.split('(')[1].split(',')
      return {
        x: parseFloat(value[0]) || 0,
        y: parseFloat(value[1]) || 0,
      }
    } catch (e) {
      return {
        x: 0,
        y: 0,
      }
    }
  }

  animationDuration(val) {
    const delta = Math.min(
      this.option.text.animationDuration,
      Date.now() - this.startTime
    )
    return (delta / this.option.text.animationDuration) * (val || 0)
  }

  animationCallback() {
    const scrollWidth = this.brandTextNode.scrollWidth

    this.animationFrame = window.requestAnimationFrame(() => {
      const transform = this.getParseFloat(this.brandTextNode.style.transform)
      this.brandTextNode.style.transform = `translate3d(-${this.animationDuration(
        scrollWidth + getWatermarkContainNode().offsetWidth
      )}px,0,0)`

      if (
        Math.abs(transform.x) <
        scrollWidth + getWatermarkContainNode().offsetWidth
      ) {
        this.animationCallback()
      } else {
        this.init()
      }
    })
  }

  // 添加水印
  add() {
    // 开始的时候 只默认添加节点
    let html = `<div>`

    // 添加品牌文字
    if (this.option.text.is_copyright) {
      // 计算位置
      let position2 = ''
      switch (Number(this.option.text.position)) {
        case 1:
          position2 = 'top:10px !important;'
          break
        case 2:
          position2 = 'top:45% !important;'
          break
        case 3:
          position2 = 'bottom:10px !important;'
          break
      }
      html += `
        <div class="dk-danmaku" style="z-index: 1 !important;display:block !important;opacity:1 !important;visibility: visible !important;position: absolute !important;${position2}left:10px !important;right:10px !important;line-height: 35px !important;overflow: hidden !important;font-size: 14px !important;background: rgba(0,0,0,0.7) !important;">
          <div style="white-space: nowrap !important;color: #fff !important;white-space: nowrap !important;display:block !important;opacity:1 !important;visibility: visible !important;">${this.option.text.content}</div>
        </div>
      `
    }

    // 添加品牌logo
    if (this.option.logo.is_watermark) {
      // 计算位置
      let position1 = ''
      // 水印出现位置 1 左上角 2 右上角 3 左下角 4 右下角 5 居中 默认2
      switch (Number(this.option.logo.position)) {
        case 1:
          position1 = 'left:10px !important;top:10px !important;'
          break
        case 2:
          position1 = 'right:10px !important;top:10px !important;'
          break
        case 3:
          position1 = 'left:10px !important;bottom:10px !important;'
          break
        case 4:
          position1 = 'right:10px !important;bottom:10px !important;'
          break
        case 5:
          position1 =
            'top:50% !important;left:50% !important;transform:translate(-50%, -50%);'
          break
      }
      html += `<div style="visibility: visible !important;position: absolute !important;${position1}overflow: hidden !important;width: ${this.option.logo.size}% !important;opacity:${this.option.logo.opacity} !important;display:block !important;">
        <img style="display:block !important;opacity:1 !important;visibility: visible !important;width:100% !important;height: auto !important;user-select:none !important;margin:0 auto !important" src="${this.option.logo.url}">
      </div>`
    }

    html += `</div>`

    // 添加html
    const node = getWatermarkContainNode()
    node.insertAdjacentHTML('beforeend', html)

    // 保存节点
    this._node = node.lastElementChild
  }

  remove() {
    if (this._node) {
      this._node.parentNode.removeChild(this._node)
      // 重置当前节点
      this._node = null
    }
    // 重置动画时间
    this.startTime = Date.now()

    clearInterval(this.animationStartTimer)
    // 取消动画队列
    cancelAnimationFrame(this.animationFrame)
    this.animationFrame = null
  }
}

export default {
  name: 'watermarkPreView',

  props: {
    options: Object,
  },

  data() {
    return {
      watermark: {},
      brandProtection: {},
    }
  },

  watch: {
    options: {
      handler(option) {
        this.init(option)
      },
      deep: true,
    },
  },

  mounted() {
    this.init(this.options)
  },

  // 销毁组件进行重置
  destroyed() {
    this.reset()
    _node = null
  },

  methods: {
    init(option) {
      this.reset()
      if (option.is_float_words == 1) {
        const horse_filter = [
          '',
          '用户昵称',
          '用户ID',
          '用户手机号',
          option.content || '',
        ]

        let horse_content = ''
        const contentType = (option.content_type || '').split(',')
        horse_filter.forEach((item, index) => {
          if (contentType.includes(`${index}`)) {
            horse_content += item ? `${horse_content ? '-' : ''}${item}` : ''
          }
        })

        this.watermark = new Watermark(
          {
            moveType: option.position,
            fontSizeAnimation: option.font_zoom == 1,
            horse_content: horse_content,
            timeDifference:
              option.rate == 1
                ? 0
                : option.rate == 2
                ? 30000
                : option.rate == 3
                ? 120000
                : 300000,
            animationDuration:
              (option.speed == 1 ? 6000 : option.speed == 2 ? 12000 : 24000) /
              scale,
          },
          {
            opacity: option.opacity / 100,
            'font-size': option.font_size + 'px',
            color: option.font_color,
          }
        )
      } else {
        this.watermarkReset()
      }

      // 品牌保护
      if (option.is_watermark == 1 || option.is_copyright == 1) {
        this.brandProtection = new BrandProtection({
          logo: {
            is_watermark: option.is_watermark == 1,
            url: option.watermark_url,
            size: option.watermark_size,
            opacity: option.watermark_opacity / 100,
            position: option.watermark_position,
          },
          text: {
            is_copyright: option.is_copyright == 1,
            position: option.copyright_position,
            speed: option.copyright_speed,
            content: option.copyright_content,
            animationDuration:
              (option.copyright_speed == 1
                ? 15000
                : option.copyright_speed == 2
                ? 35000
                : 60000) / scale,
          },
        })
      } else {
        this.brandProtectionReset()
      }
    },

    reset() {
      // 重置
      this.watermarkReset()
      this.brandProtectionReset()
    },

    watermarkReset() {
      this.watermark.reset && this.watermark.reset()
    },

    brandProtectionReset() {
      this.brandProtection.remove && this.brandProtection.remove()
    },
  },
}
</script>
